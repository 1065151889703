import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 下半場</h2>
          <h1 className="main_title">孫育晴</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>孫育晴以她過去不同的工作經歷帶出在不同地方、與不同對象藝術參與的經驗，提出她對公共藝術的三個想法。</p>

                <br/>
                <h4>1. 新形態的公共藝術 (New Genre Public Art)</h4>
                <div className="context onset_left_1_article">
                  <p>以公共議題為導向讓民眾介入、參與、互動，並形塑公共論述的創作。除了永久性與臨時性的公共藝術設置，也可以透過創造許多參與事件探討公領域與私領域之間的對話，邀請大家進入藝術事件。</p>
                  <ul className="context_list order_list">
                    <li>日本的創作團隊Paramodel，以玩具火車軌道、模型玩具、塑膠管等材料，依不同空間條件加入了不同參與者的共同想像進行立體的塗鴉裝置</li>
                    <li>美國的團體Improv Everywhere，在網路上召集了很多人在二二八公園跟新光三越前面做freeze和mp3的實驗</li>
                  </ul>
                </div>

                <br/>
                <h4>2. 藝術正在進行中 (Art in Progress)</h4>
                <div className="context onset_left_1_article">
                  <p>別於在美術館、畫廊的白牆空間，公共藝術是一個在生活的場域進行中的事件。在大家生活場域裡面，包括吃喝這些事情都可以成為藝術。透過實際共同參與藝術，抹除生活成長中沒有藝術經驗的人的隔閡。</p>
                  <ul className="context_list order_list">
                    <li>幸田千依《圖畫遊行》，邀請台灣的民眾在牆上貼上食物或景點，再帶著這些圖案尋找這些空間和東西。</li>
                    <li>泰國藝術家Jiandyin在工作室開放時請台灣的民眾帶各式黑白切，再將之轉變成泰國菜。</li>
                    <li>本身是米其林的廚師的日本藝術家Koji Nagao，來台灣吃了一百碗牛肉麵，將它們記錄下來，成為一個系列的作品。</li>
                    <li>周靈芝寶藏巖與居民共同共創的生態園，剛開始許多居民很反抗公共藝術，認為為何要讓外面不是住在這裡的人進來看我們居住的環境，後來他們卻也開始把他種的南瓜、冬瓜從地面拉到天上希望大家經過看到它的美</li>
                    <li>寶藏巖邀請沒有表演經驗的住民一同加入計畫，例如在地居民胡伯伯</li>
                  </ul>
                </div>

                <br/>
                <h4>3. 因地制宜 (Site Specific)</h4>
                <div className="context onset_left_1_article">
                  <p>如何讓永久性的作品和這個空間、環境產生對話，如何和人產生對話、創造有感，並針對不同的對象創造不同的在地對話。孫育晴表示在嘉義做公共藝術也別於過往於其他地區的經驗，而這些藝術參與案例都可以讓民眾直接連結到生活。</p>
                  <ul className="context_list order_list">
                    <li>過年時邀請八家將參與節慶的活動。</li>
                    <li>邀請舊酒廠的老員工舉辦舊酒廠回娘家。</li>
                    <li>小老闆市集。不同於台北，嘉義參與族群都是家庭。一般文創市集都是大人舉辦的，特地做了一個12歲以下的小老闆市集。</li>
                    <li>舉辦開齋節。南部有非常多新住民，透過藝術參與讓小朋友從小認識這樣的環境</li>
                  </ul>
                </div>

                <br/>
                <p>綜合上述案例分享，孫育晴觀察到，居民、藝術家、規劃者的想像會是不一樣的狀況；居民想像的是一個環境舒服的生活空間，藝術家想像的是如何透過藝術跟環境跟這些人產生更多的關係，規劃者想像的是希望如何讓創作者和空間使用者產生一個更好的對話關係。 </p>

              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_1_12" className="next_page">
            <div className="next_title">主持人評論與討論回應</div>
            <div className="next_owner">20 X 20 / 下半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
